<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/ti-branch-min.png')"
              max-height="50px"
              max-width="50px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              Tequila Inteligente
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="mb-2 text-center">
            Por favor inicia sesión para acceder a más características
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form @submit.prevent="login">
            <v-text-field
              v-model="user"
              outlined
              label="Usuario"
              placeholder="john@tequila.com"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-alert
              v-if="error"
              color="red"
              type="error"
              dense
              class="mt-3"
            >
              Has introducido mal el email o la contraseña.
            </v-alert>

            <v-btn
              block
              color="primary"
              class="mt-6"
              type="submit"
              value="Login"
            >
              Login
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img
      class="auth-tree"
      height="185"
      contain
      src="@/assets/images/misc/agave2.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      height="289"
      contain
      src="@/assets/images/misc/agave2.png"
    ></v-img>

    <v-img
      class="vanti-logo mt-6"
      height="100px"
      contain
      src="@/assets/images/logos/vanti_grey.webp"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import auth from '@/services/auth'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const user = ref('')
    const password = ref('')

    return {
      isPasswordVisible,
      user,
      password,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  data: () => ({
    user: '',
    password: '',
    error: false,
  }),
  methods: {
    async login() {
      auth.login(this.user, this.password).then(resp => {
        this.error = false
        auth.setUserLogged(resp.data.Response.AuthToken, resp.data.Response.Name)
        if (this.$route.query.return) {
          this.$router.push(this.$route.query.return)
        } else {
          this.$router.push('/home')
        }
      }).catch(error => {
        this.error = true
        console.log(error.message)
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
